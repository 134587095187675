<script setup lang="ts">
  import NameInput from '~/UI/Inputs/NameInput.vue';
  import PhoneInput from '~/UI/Inputs/PhoneInput.vue';
  import Checkbox from '~/UI/Inputs/Checkbox.vue';
  import EcButton from '~/UI/Buttons/EcButton.vue';
  import YandexCaptchaWrapper from '~/UI/Yandex/CaptchaWrapper.vue';
  import doctorInfoFirstPhotoBySize from '~/utils/doctorInfo/FirstPhotoBySize';

  const bookingStore = useBookingStore();
  const pageInfoStore = usePageInfoStore();
  const { doctorsCacheMap, mainGroupCount } = storeToRefs(useDoctorsStore());

  const captchaWrapper = ref<HTMLElement | null>(null);
  const error = ref<string>('');

  const submit = () => {
    bookingStore.loading = true;
    error.value = '';
    if (!bookingStore.validate()) {
      setTimeout(() => {
        bookingStore.loading = false;
      }, 100);
      return;
    }
    if (captchaWrapper.value) {
      // @ts-expect-error it's ok
      captchaWrapper.value?.captchaSubmit();
    } else {
      setTimeout(() => {
        error.value = 'Что-то пошло не так. Попробуйте обновить страницу и отправить форму ещё раз.';
        bookingStore.loading = false;
      }, 300);
    }
  };
  const callbackToPass = () => {
    bookingStore.bookShort(true);
  };

  const doctorsIter = () => {
    const doctors: string[] = [];
    let i = 0;
    const maxCount = mainGroupCount.value >= 3 ? 3 : mainGroupCount.value;
    for (const doctor of doctorsCacheMap.value.values()) {
      if (i === maxCount) {
        break;
      }
      const photo = doctorInfoFirstPhotoBySize(doctor, '232x269');
      doctors.push(photo.url);
      i++;
    }
    return doctors;
  };

  const getDoctorsCase = computed(() => {
    const count = mainGroupCount.value;
    const titles = ['врач', 'врача', 'врачей'];
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[(count % 100 > 4 && count % 100 < 20) ? 2 : cases[(count % 10 < 5) ? count % 10 : 5]];
  });

  const helpFormEl = ref();

  const navigationStore = useNavigationStore();

  useIntersectionObserver(
    helpFormEl,
    ([{ isIntersecting }]) => {
      const tab = navigationStore.navigationTabs.find((t) => t.link === 'help-form');
      if (tab) {
        tab.isActive = isIntersecting;
      }
    },
    {
      rootMargin: '-120px 0px 0px 0px'
    }
  );
  const { isHelpMessageSendedSuccess } = storeToRefs(useBookingStore());
</script>

<template>
  <div ref="helpFormEl" class="patient-help-form-with-title form-main-container">
    <span id="help-form" data-nav="Записаться" class="patient-help-form-title scroll-normalize form-title-main-container">Записаться на приём</span>
    <div class="patient-help-form-container">
      <div v-if="!isHelpMessageSendedSuccess" class="patient-help-form-flex">
        <div class="patient-help-form">
          <span class="form-title">Записаться на приём</span>
          <form class="form-block">
            <NameInput />
            <PhoneInput />
            <Checkbox />
            <ClientOnly>
              <YandexCaptchaWrapper
                ref="captchaWrapper"
                :callback="callbackToPass"
              />
            </ClientOnly>
            <div v-if="error" class="input-error-message">
              {{ error }}
            </div>
            <div class="booking__dialog__item button button-container">
              <EcButton
                :loading="bookingStore.loading"
                :disable="bookingStore.loading"
                class="primary full-width shadow-button services-button-container pointer select-doctor-button help-button"
                @click="submit"
              >
                <span>Записаться</span>
              </EcButton>
            </div>
          </form>
        </div>
        <div v-if="mainGroupCount > 0" class="doctors-list-container">
          <div class="doctors-list">
            <div class="doctors-img-container">
              <img
                v-for="doctorPhoto in doctorsIter()"
                :key="doctorPhoto"
                :src="doctorPhoto"
                class="doctor-img"
              >
            </div>
            <div class="doctors-list-text-container">
              <span class="doctors-list-title">{{ mainGroupCount }} {{ getDoctorsCase }}</span>
              <span class="doctors-list-text">{{ mainGroupCount > 1 ? 'готовы':'готов' }} вам помочь</span>
            </div>
          </div>
          <div class="doctors-list-banner">
            <IconFormCheck filled class="doctors-list-banner-icon" />
            <span class="doctors-list-banner-text">Мы подберем врача, доступного уже сегодня</span>
          </div>
        </div>
      </div>
      <div v-else class="patient-help-form-sended-container">
        <img loading="lazy" src="~/assets/img/patient-help-form-sended.gif" class="patient-help-form-sended-img">
        <span class="patient-help-form-sended-main-text">Мы начали подбирать для вас врача и скоро свяжемся с вами</span>
        <button type="button" class="patient-help-form-sended-button" @click="isHelpMessageSendedSuccess = false">
          Хорошо, буду ждать
        </button>
        <div class="line"></div>
        <div class="patient-help-form-sended-bottom">
          <span class="patient-help-form-sended-bottom-text-first">Если не любите ждать, то можете сейчас с нами связаться:</span>
          <NuxtLink :href="pageInfoStore.getLinkPhone" class="telephone">
            {{ pageInfoStore.getFormattedMainPhone }}
          </NuxtLink>
          <span class="patient-help-form-sended-bottom-text-second">А если не любите голосом — напишите:</span>
          <div class="buttons-container">
            <NuxtLink target="_blank" :href="pageInfoStore.getWhatsAppPhone" rel="nofollow" class="messenger">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                <mask
                  id="mask0_17365_316861"
                  style="mask-type:luminance"
                  maskUnits="userSpaceOnUse"
                  x="2"
                  y="2"
                  width="20"
                  height="20"
                >
                  <path d="M2.75 2.5H21.75V21.5H2.75V2.5Z" fill="white"></path>
                </mask>
                <g mask="url(#mask0_17365_316861)">
                  <path d="M14.04 2.64002L13.47 2.56002C11.7569 2.31268 10.0088 2.55737 8.42954 3.26558C6.85023 3.97379 5.50476 5.11634 4.55 6.56002C3.53416 7.94 2.92859 9.57876 2.80298 11.2877C2.67737 12.9967 3.03684 14.7063 3.84 16.22C3.9222 16.3717 3.97337 16.5383 3.99054 16.71C4.00771 16.8817 3.99053 17.055 3.94 17.22C3.53 18.63 3.15 20.05 2.75 21.54L3.25 21.39C4.6 21.03 5.95 20.67 7.3 20.34C7.58494 20.2808 7.88112 20.3087 8.15 20.42C9.3612 21.0111 10.6848 21.3363 12.032 21.3738C13.3793 21.4113 14.7189 21.1601 15.9611 20.6372C17.2033 20.1144 18.3192 19.3318 19.2341 18.3421C20.149 17.3525 20.8415 16.1785 21.2653 14.8992C21.6892 13.6198 21.8344 12.2646 21.6914 10.9244C21.5483 9.58429 21.1203 8.29026 20.4359 7.12917C19.7516 5.96809 18.8269 4.96681 17.7237 4.19254C16.6206 3.41827 15.3646 2.88892 14.04 2.64002ZM16.56 15.76C16.1966 16.0854 15.7534 16.3087 15.2756 16.407C14.7978 16.5054 14.3024 16.4754 13.84 16.32C11.7446 15.73 9.92661 14.4152 8.71 12.61C8.24529 11.9715 7.8717 11.2715 7.6 10.53C7.45285 10.0998 7.42632 9.63749 7.52327 9.19325C7.62023 8.74902 7.83698 8.33981 8.15 8.01002C8.30239 7.81553 8.50981 7.67145 8.74526 7.59654C8.9807 7.52162 9.23325 7.51935 9.47 7.59002C9.67 7.64002 9.81 7.93002 9.99 8.15002C10.136 8.56302 10.307 8.96702 10.5 9.36002C10.6464 9.56053 10.7076 9.81082 10.6701 10.0563C10.6326 10.3017 10.4996 10.5223 10.3 10.67C9.85 11.07 9.92 11.4 10.24 11.85C10.9474 12.8692 11.9236 13.6723 13.06 14.17C13.38 14.31 13.62 14.34 13.83 14.01C13.92 13.88 14.04 13.77 14.14 13.65C14.72 12.92 14.54 12.93 15.46 13.33C15.753 13.453 16.037 13.597 16.31 13.76C16.58 13.92 16.99 14.09 17.05 14.33C17.1077 14.5904 17.0925 14.8616 17.0061 15.1139C16.9196 15.3662 16.7653 15.5898 16.56 15.76Z" fill="#262633"></path>
                </g>
              </svg>
              WhatsApp
            </NuxtLink>
            <NuxtLink target="_blank" href="https://t.me/eastclinic_bot" class="messenger">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                <g clip-path="url(#clip0_17365_316872)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M20.527 4.42997C20.7741 4.32596 21.0446 4.29008 21.3103 4.32608C21.576 4.36208 21.8272 4.46863 22.0377 4.63465C22.2482 4.80067 22.4104 5.02008 22.5074 5.27005C22.6043 5.52002 22.6325 5.79141 22.589 6.05597L20.321 19.813C20.101 21.14 18.645 21.901 17.428 21.24C16.41 20.687 14.898 19.835 13.538 18.946C12.858 18.501 10.775 17.076 11.031 16.062C11.251 15.195 14.751 11.937 16.751 9.99997C17.536 9.23897 17.178 8.79997 16.251 9.49997C13.949 11.238 10.253 13.881 9.03102 14.625C7.95302 15.281 7.39102 15.393 6.71902 15.281C5.49302 15.077 4.35602 14.761 3.42802 14.376C2.17402 13.856 2.23502 12.132 3.42702 11.63L20.527 4.42997Z" fill="#333333"></path>
                </g>
                <defs>
                  <clipPath id="clip0_17365_316872">
                    <rect width="24" height="24" fill="white" transform="translate(0.75)"></rect>
                  </clipPath>
                </defs>
              </svg>
              Telegram
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.form-main-container {
  @media (min-width: 1023px) {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
    @media (max-width: 1170px) {
      padding: 0 40px;
    }
    @media (max-width: 1075px) {
      padding: 0 20px;
    }
  }
}
.form-title-main-container {
  @media (max-width: 1023px) {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
    @media (max-width: 1170px) {
      padding: 0 40px;
    }
    @media (max-width: 1075px) {
      padding: 0 20px;
    }
  }
}
.patient-help-form-with-title {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 40px 0px;
  @media (max-width: 1023px) {
    gap: 30px;
    padding: 30px 0px;
  }
}
.scroll-normalize {
  scroll-margin-top: 160px;
  @media (max-width: 1023px) {
    scroll-margin-top: 120px;
  }
}
.patient-help-form-title {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #262633;
  font-family: 'Roboto';
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.085px;
  @media (max-width: 1023px) {
    font-size: 24px;
    line-height: 28px;
  }
}
.patient-help-form-container {
  padding: 60px 80px;
  border-radius: 40px;
  border-top: 4px solid #2D7FF9;
  border-bottom: 4px solid #2D7FF9;
  background-color: white;
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.15);
  @media (max-width: 1023px) {
    padding: 28px 20px;
    border-radius: 0;
  }
}
.patient-help-form-flex {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 60px;
  @media (max-width: 1023px) {
    flex-direction: column-reverse;
    gap: 32px;
  }
}
.patient-help-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media (max-width: 1023px) {
    gap: 16px;
  }
}
.form-title {
  color: #262633;
  font-family: 'Roboto';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  @media (max-width: 1023px) {
    font-size: 18px;
    line-height: 24px;
  }
}
.form-block {
  max-width: 475px;
  @media (max-width: 1023px) {
    max-width: 100%;
  }
}
.button-container {
  display: flex;
  justify-content: start;
  @media (max-width: 1023px) {
    width: 100%;
  }
}
.help-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 56px;
  padding: 14px 32px;
  margin-top: -20px;
  @media (max-width: 1023px) {
    width: 100%;
  }
}
.doctors-list-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
}
.doctors-list {
  display: flex;
  gap: 8px;
}
.doctors-img-container {
  display: flex;
  align-items: center;
  min-width: 80px;
}
.doctor-img {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  border: 3px solid white;
  background-color: white;
  object-fit: cover;
  object-position: center top;
  margin-right: -20px;
}
.doctors-list-text-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.doctors-list-title {
  color: #262633;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}
.doctors-list-text {
  color: #666E81;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.048px;
}
.doctors-list-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  border-radius: 20px;
  background-color: #EBF1FF;
  max-width: fit-content;
}
.doctors-list-banner-icon {
  font-size: 20px;
  min-width: 20px;
  margin: 0;
}
.doctors-list-banner-text {
  color: #262633;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.patient-help-form-sended-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
}
.patient-help-form-sended-img {
  height: 120px;
}
.patient-help-form-sended-main-text {
  color: #262633;
  text-align: center;
  font-family: 'Roboto';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  max-width: 360px;
  @media (max-width: 1023px) {
    font-size: 18px;
    line-height: 24px;
  }
}
.patient-help-form-sended-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  padding: 14px 32px;
  border-radius: 15px;
  background-color: #2D7FF9;
  box-shadow: 0px 4px 15px 0px rgba(127, 81, 255, 0.20);
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}
.line {
  width: 100%;
  max-width: 496px;
  height: 1px;
  background-color: #E4E7EF;
}
.patient-help-form-sended-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.patient-help-form-sended-bottom-text-first {
  color: #262633;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.patient-help-form-sended-bottom-text-second {
  color: #666E81;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.telephone {
  color: #2D7FF9 !important;
  text-decoration: none;
  text-align: center;
  font-family: 'Roboto';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.buttons-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
.messenger {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
  padding: 14px 16px;
  border-radius: 15px;
  background-color: #F2F3F6;
  text-decoration: none;
  color: #262633;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  cursor: pointer;
}
</style>
